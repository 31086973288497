import Main from './components/Main'
import {
  BrowserRouter as Router, Routes, Route
} from "react-router-dom";
import SuccessPayment from './components/Success';
import Footer from './components/shared/Footer';
import Terms from './components/Terms';
import InterestSuccess from './components/InterestSuccess';

const App = () => {

  return (
    <Router>
      <Routes>
        <Route exact path='/:orgId/events' element={<Main scope={"ticket"} />}  />
        <Route path='/:orgId/events/:eventId' element={<Main scope={"ticket"} />}  />

        <Route path='/:orgId/events/:eventId/occur/:occId' element={<Main scope={"ticket"} />}  />
        
        <Route path='/:orgId/interests/:interestId' element={<Main scope={"interest"} />}  />
        <Route path='/:orgId/interests' element={<Main scope={"interest"} />}  />

        <Route exact path='/:orgId' element={<Main scope={"organization"} />} />
        
        <Route path='/:orgId/packages' element={<Main scope={"package"}  />}  />
        <Route path='/:orgId/packages/:packageId' element={<Main scope={"package"}  />}  />
        <Route path="/success" element={<SuccessPayment />} />
        <Route path="/interest/success" element={<InterestSuccess />} />

        <Route path="/terms" element={<Terms />} />
      </Routes>

      <Footer/>
    </Router>
  )
}

export default App