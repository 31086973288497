import React from 'react';
import { getInterestDescription, getInterestName, getLocalizedOrganizationName } from './shared/Util';

const InterestCard = ({ interest, organization_details, language }) => {

  const organizationName = getLocalizedOrganizationName(interest?.organization_event_occurrences?.[0], language)
  
  const renderOrganizationName = () => {
    if (organizationName) {
      return <span className="orgName">{organizationName}</span>
    }

    if (organization_details) {
      return <span className="orgName">{organization_details.display_name ?? organization_details.name}</span>
    }
    return null
  }
  
  // if interest is connected to event - show event image etc, same as event page?
  return (
    <>
      {interest &&
        <div className="container membership packages">
          <div className="row overflow-hidden">
            <div className="col-12 mx-auto" id={'interest-' + interest.id}>

                <div className="row overflow-hidden"><div className="col-12 mx-auto">
                    <div className="card mw-container">
                      {organization_details && organization_details.image_url && <img src={organization_details.image_url} className="w-100" alt="" />}
                      <div className="card-body bg-white d-flex justify-content-between align-items-center ">
                        <div className="wrap-date-location">
                          <span className="location">{getInterestName(interest, language)}</span>
                          {renderOrganizationName()}
                        </div>
                        {organization_details && organization_details.logo_image_url && 
                          <a href={`/${organization_details.organization_id}`}>
                            <img src={organization_details.logo_image_url} height="65" alt="logo" className="unionLogo" />
                          </a>
                        }
                      </div>
                    </div>
                  </div>
                </div>
               

              <div className="card mw-container packages-item">
                  <div className="card-footer">
                    <div className="card-text">
                      <div dangerouslySetInnerHTML={{ __html: getInterestDescription(interest, language) }} />
                    </div>
                  </div>
              </div>

            </div>
          </div>
        </div>
      }
    </>
  )

}

export default InterestCard