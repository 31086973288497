import Countdown from "./Countdown";
import React, { useState, useEffect } from 'react';
import DateRangeFormat from './DateRangeFormat';
import { Link } from 'react-router-dom';
import refreshSvg from './shared/refresh.svg'
import { TICKET_COUNT_SELECT } from "./shared/Steps";

const moment = require('moment');
require('moment-timezone');

const EventCardList = ({ nextStep, event, handleChange, values, checkbox, count, markInactive, eventId, handleEventUpdate, eventsAll, parentActiveTab }) => {
  var { selected_ticket, selected_occurrence, ticket, meta, organization_details } = values

  // Store the local tab state but sync with parent when needed
  const [activeTab, setActiveTab] = useState('Upcoming');

  // This effect syncs the parent tab state with the local state
  useEffect(() => {
    if (parentActiveTab) {
      // Map parent tab names to local tab names
      if (parentActiveTab === 'Upcoming Events') {
        setActiveTab('Upcoming');
      } else if (parentActiveTab === 'Past Events') {
        setActiveTab('Past');
      }
    }
  }, [parentActiveTab]);

  const Continue = () => {
    nextStep(TICKET_COUNT_SELECT)
  }

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const Tab = ({ activeTab, label, onClick }) => (
    <li className="nav-item" role="presentation">
      <button className={activeTab === label ? 'active nav-link' : 'nav-link'} onClick={() => onClick(label)}
        data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-selected={activeTab === label ? "true" : "false"}>{label}</button>
    </li>
  );

  const getTicketMeta = (ticketId) => {
    if (meta && meta.tickets && meta.tickets.length > 0) {
      for (var ticketsMeta of meta.tickets) {
        if (ticketsMeta.id === ticketId) {
          return ticketsMeta
        }
      }
    }
    return null
  }

  const toPriceString = (priceInSmallestUnit, currency) => {
    return parseFloat(priceInSmallestUnit / 100).toFixed(2) + ' ' + currency
  }

  const getTotalPrice = (meta, price, currency) => {
    let priceInSmallestUnit = parseInt(meta.count) * price
    return toPriceString(priceInSmallestUnit, currency)
  }

  const renderTicket = (ticket, showCount, eventId, occurrenceId) => {
    let ticketsMeta = getTicketMeta(ticket.id)

    const stockholmTime = moment().tz('Europe/Stockholm');
    const stockholmTimeString = stockholmTime.format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';

    let upcomingTicket = ticket.start_date > stockholmTimeString

    return <label className="d-block px-0" htmlFor={ticket.id} key={ticket.id}><div className={!ticket.is_active && !upcomingTicket ? 'event-ticket passed row align-items-center' : 'row align-items-center'} key={ticket.id}>
      <div className="col-6">
        <dl className="name">
          <span>{ticket.name}</span>
          <dt className="event-price">{toPriceString(ticket.price, ticket.currency)}</dt>
          {ticket.ticket_statistics && <dt>{ticket.ticket_statistics.count} <small>{ticket.ticket_statistics.count > 1 ? 'Tickets Left' : 'Ticket Left'}</small></dt>}

          {ticket.ticket_statistics && ticket.ticket_statistics.count !== 0 && ticket.is_active &&
            <span className="col-12 upcoming">
              <Countdown countMessage={'Available for:'} eventId={eventId} occurrenceId={occurrenceId} ticketId={ticket.id} startDate={ticket.end_date} onCountdownFinished={handleCountdownFinished} />
            </span>
          }
        </dl>
      </div>

      {showCount &&
        <div className="col-6 d-flex justify-content-center">
          <dl className="tickets">
            <dt>Quantity</dt>
            <dd>{ticketsMeta.count}</dd>
            <dt>Total price</dt>
            <dd>{getTotalPrice(ticketsMeta, ticket.price, ticket.currency)}</dd>
          </dl></div>}

      {checkbox ?
        !ticket.is_active && upcomingTicket ?
          <span className="col-6 upcoming d-flex justify-content-center"><Countdown countMessage={'Available in:'} eventId={eventId} occurrenceId={occurrenceId} ticketId={ticket.id} startDate={ticket.start_date} onCountdownFinished={handleCountdownFinished} /></span>
          : !ticket.is_active ?
            <span className="col-6 d-flex justify-content-center">Passed</span>
            : ticket.ticket_statistics.count === 0 ?
              <span className="col-6 d-flex justify-content-center">Sold Out</span>
              :
              <div className="col-6 form-check d-flex justify-content-center">
                <input className="form-check-input" type="radio" name="selected_ticket" id={ticket.id}
                  value={ticket.id}
                  onChange={(e) => handleChange('selected_ticket', parseInt(e.target.value))}
                  checked={selected_ticket === ticket.id} />
              </div> : ''}
    </div>
    </label>
  }

  const renderOccurrenceAndTicket = (occurrence, ticket) => {
    return <>
      <div className="row" key={occurrence.id}>
        <div className="col-12">
          <DateRangeFormat occurrence={occurrence} organization={organization_details} />
          <dl>
            {markInactive && occurrence.date_has_passed && <span className="tag-passed"><dd>Passed</dd></span>}
          </dl>
        </div>
      </div>

      {renderTicket(ticket, true)}
    </>
  }

  const handleCountdownFinished = (eventId, occurrenceId, ticketId) => {
    // Create a new array of occurrences with the updated is_active property
    const updatedOccurrences = event.organization_event_occurrences.map((occurrence) => {
      if (occurrence.id === occurrenceId) {
        const updatedTickets = occurrence.tickets.map((ticket) => {
          if (ticket.id === ticketId) {
            return { ...ticket, is_active: true };
          }
          return ticket;
        });

        return { ...occurrence, tickets: updatedTickets };
      }

      return occurrence;
    });

    // Create a new event object with the updated occurrences
    const updatedEvent = { ...event, organization_event_occurrences: updatedOccurrences };

    // Call the handleEventUpdate callback function to pass the updated event back to the Main component
    handleEventUpdate(updatedEvent);
  };

  const handleClickOccur = (orgId, eventId, occId) => {
    window.location.href = '/' + orgId + '/events/' + eventId + '/occur/' + occId
  }

  const renderOccurrencesAll = (eventsAll) => {
    // render all occurrence / tickets
    if (eventsAll && eventsAll.organization_event_occurrences && eventsAll.organization_event_occurrences.length > 0) {
      // For eventsAll, we can use the events directly without filtering
      // The parent already provides the correct events based on the active tab
      let filteredOccurrences = eventsAll.organization_event_occurrences;
      
      // Additional sorting (parent component already does most of this work)
      if (parentActiveTab === 'Past Events') {
        // Ensure past events are in reverse chronological order
        filteredOccurrences = [...filteredOccurrences].sort((a, b) => {
          return new Date(b.start_date) - new Date(a.start_date);
        });
      }

      if (filteredOccurrences.length === 0) {
        return (
          <div className="container membership">
            <div className="row">
              <div className="col-12 text-center py-4">
                <p>No {parentActiveTab ? parentActiveTab.toLowerCase() : 'events'} found.</p>
              </div>
            </div>
          </div>
        );
      }

      return filteredOccurrences.map(function (occurrence, i) {
        return <div className={"container membership allEvents"} key={i}>
          <div className="row overflow-hidden">
            <div className="col-12 mx-auto" id={'event-all'}>
              <div className="card mw-container">
                <div onClick={() => handleClickOccur(eventsAll.organization.id, occurrence.organization_event_id, occurrence.id)} className="occurrence">
                  {eventsAll && occurrence.image_url && <img src={occurrence.image_url} className="w-100" alt="" />}
                  <div className="overflow-hidden p-0 m-0 bg-light" key={i}>
                    <div className="row bg-white event-ticket-date ">
                      <div className="col-12">
                        {eventsAll && <DateRangeFormat occurrence={occurrence} organization={eventsAll.organization} showRecurring={true} />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      });
    }
  }

  const renderOccurrences = (event, selected_occurrence, ticket) => {
    // render only the selected ticket / occurrence
    if (selected_occurrence && ticket) {
      return renderOccurrenceAndTicket(selected_occurrence, ticket)
    }

    // render all occurrence / tickets
    if (event && event.organization_event_occurrences && event.organization_event_occurrences.length > 0) {
      // Filter occurrences based on the active tab
      const currentTime = moment().tz('Europe/Stockholm');
      let filteredOccurrences = event.organization_event_occurrences.filter(occurrence => {
        const occurrenceEndTime = moment(occurrence.end_date || occurrence.start_date);
        return activeTab === 'Upcoming' ? 
          occurrenceEndTime.isAfter(currentTime) : 
          occurrenceEndTime.isBefore(currentTime);
      });
      
      // Sort events: upcoming in chronological order, past in reverse chronological order
      filteredOccurrences = filteredOccurrences.sort((a, b) => {
        const dateA = moment(a.start_date);
        const dateB = moment(b.start_date);
        return activeTab === 'Upcoming' ? 
          dateA.diff(dateB) : // Ascending for upcoming
          dateB.diff(dateA);  // Descending for past
      });

      if (filteredOccurrences.length === 0) {
        return (
          <div className="row">
            <div className="col-12 text-center py-4">
              <p>No {activeTab === 'Upcoming' ? 'upcoming' : 'past'} events found.</p>
            </div>
          </div>
        );
      }

      return filteredOccurrences.map(function (occurrence, i) {
        let closedEvent = occurrence.date_has_passed ? '' : '';

        return <div className={'row bg-white event-ticket-date ' + closedEvent} key={i}>
          <div className="col-12">
            <div onClick={() => handleClickOccur(event.organization_id, event.id, occurrence.id)} >
              {event && <DateRangeFormat occurrence={occurrence} organization={event.organization} />}
            </div>
          </div>
        </div>
      })
    }
  }

  return (
    <>
      {event ?
        <div className={eventId ? "container membership eventPage" : "container membership"} >
          <div className="row overflow-hidden">
            <div className="col-12 mx-auto" id={'event-' + event.id}>
              <div className="card mw-container" htmlFor={event.id}>
                {!eventId && !selected_ticket ?
                  <a href={'/' + event.organization_id + '/events/' + event.id}>
                    {event && event.image_url && <img src={event.image_url} className="w-100" alt="" />}
                    <div className="card-body bg-white d-flex justify-content-between align-items-center">
                      <h5 className="card-title">{event.title && event.title}</h5>
                      {organization_details &&
                        <a href={`/${organization_details.organization_id}`}>
                          <img src={organization_details.logo_image_url} height={65} alt="logo" className="unionLogo" />
                        </a>
                      }
                    </div>
                  </a>
                  :
                  <>
                    {event && event.image_url && <img src={event.image_url} className="w-100" alt="" />}
                    <div className="card-body bg-white  d-flex justify-content-between align-items-center">
                      <h5 className="card-title">{event.title && event.title}</h5>
                      {organization_details &&
                        <a href={`/${organization_details.organization_id}`}>
                          <img src={organization_details.logo_image_url} height={65} alt="logo" className="unionLogo" />
                        </a>
                      }
                    </div>
                    <ul className="nav nav-pills" id="myTab" role="tablist">
                      <Tab activeTab={activeTab} label="Upcoming" onClick={handleTabClick} />
                      <Tab activeTab={activeTab} label="Past" onClick={handleTabClick} />
                      <Tab activeTab={activeTab} label="About" onClick={handleTabClick} />
                    </ul>

                    {activeTab === 'About' && (
                      <div className="card-footer">
                        <div className="card-text">
                          {event.content &&
                            <div dangerouslySetInnerHTML={{ __html: event.content }} />
                          }
                        </div>
                      </div>)
                    }
                  </>
                }

                <div className="card-footer overflow-hidden p-0 m-0 bg-light">
                  {(eventId || selected_ticket) && activeTab !== 'About' &&
                    renderOccurrences(event, selected_occurrence, ticket)
                  }
                </div>
              </div>
            </div>
          </div>
        </div >
        :
        eventsAll &&
        renderOccurrencesAll(eventsAll)
      }
    </>
  )
}

export default EventCardList