import React, { useState } from "react";
import EventCard from "./EventCard";
import Header from './shared/Header'
import { TICKET_COUNT_SELECT } from "./shared/Steps";
import EventCardList from "./EventCardList";

const Events = ({ nextStep, handleChange, eventId, values, handleEventUpdate }) => {

  var { first_name, events, selected_ticket, meta, organization_details } = values
  const [activeTab, setActiveTab] = useState('Upcoming Events');

  // for continue event listener
  const Continue = e => {
    e.preventDefault();
    nextStep(TICKET_COUNT_SELECT)
  }

  // filter eventId
  if (eventId) {
    if (events && events.length > 0) {
      events = events.filter((event) => event.id === parseInt(eventId))
    }
  }

  let eventsOccur = []
  const currentYear = new Date().getFullYear();

  if (events.length > 0) {
    events.map(function (event, i) {
      if (event.organization_event_occurrences) {
        for (let index = 0; index < event.organization_event_occurrences.length; index++) {
          // Add basic event info
          event.organization_event_occurrences[index].image_url = event.image_url
          event.organization_event_occurrences[index].title = event.title
          
          // Add year display flag
          const eventDate = new Date(event.organization_event_occurrences[index].start_date);
          event.organization_event_occurrences[index].show_year = eventDate.getFullYear() !== currentYear;
          
          const element = event.organization_event_occurrences[index];
          eventsOccur.push(element)
        }
      }
    })
  }

  eventsOccur.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
  
  // Separate events into upcoming and past
  const currentDate = new Date();
  const upcomingEvents = eventsOccur.filter(event => new Date(event.start_date) >= currentDate);
  
  // For past events, we want reverse chronological order (most recent first)
  const pastEvents = eventsOccur
    .filter(event => new Date(event.start_date) < currentDate)
    .sort((a, b) => new Date(b.start_date) - new Date(a.start_date)); // Reverse chronological order
  
  // Create separate event objects for upcoming and past events
  let upcomingEventsObj = {
    organization_event_occurrences: upcomingEvents,
    organization: events[0]?.organization
  }
  
  let pastEventsObj = {
    organization_event_occurrences: pastEvents,
    organization: events[0]?.organization
  }

  const handleTabChange = (tab) => {
    console.log("Parent: Tab changed to:", tab);
    setActiveTab(tab);
  };

  return (
    <>
      <Header organization_details={organization_details ? organization_details : ''} first_name={first_name} />

      {events.length < 1 ? (
        <div className="container">
          <div className="row">
            <div className="col-12 mx-auto my-4 apply-btn mw-container">
              <small>We're sorry, but at this time there are no events available. Please check back later as new events may become available.</small>
            </div>
          </div>
        </div>
      ) : !eventId ? (
        <>
          {/* Organization Info Section */}
          <div className="container membership allEvents">
            <div className="row overflow-hidden">
              <div className="col-12 mx-auto">
                <div className="card mw-container">
                  {organization_details && organization_details.image_url && (
                    <img src={organization_details.image_url} className="w-100 topHeader" alt={organization_details.name} />
                  )}
                  <div className="card-body bg-white d-flex justify-content-between align-items-center">
                    {/* <h5 className="card-title">{organization_details && organization_details.name}</h5> */}
                    <h5 className="card-title">Events</h5>
                    {organization_details && organization_details.logo_image_url && (
                      <a href={`/${organization_details.organization_id}`}>
                        <img src={organization_details.logo_image_url} height={65} alt="logo" className="unionLogo" />
                      </a>
                    )}
                  </div>


                    {/* Tabs Navigation */}     
                    <ul className="nav nav-pills" id="eventsTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button 
                          className={`nav-link ${activeTab === 'Upcoming Events' ? 'active' : ''}`}
                          onClick={() => handleTabChange('Upcoming Events')}
                          data-bs-toggle="tab"
                          data-bs-target="#upcomingEvents" 
                          type="button" 
                          role="tab"
                          aria-selected={activeTab === 'Upcoming Events' ? 'true' : 'false'}
                        >
                          Upcoming
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button 
                          className={`nav-link ${activeTab === 'Past Events' ? 'active' : ''}`}
                          onClick={() => handleTabChange('Past Events')}
                          data-bs-toggle="tab"
                          data-bs-target="#pastEvents"
                          type="button" 
                          role="tab"
                          aria-selected={activeTab === 'Past Events' ? 'true' : 'false'}
                        >
                          Past
                        </button>
                      </li>
                    </ul>

                </div>
              </div>
            </div>
          </div>

      
             
          {/* Tab Content */}
          <div className="tab-content">
            {/* Upcoming Events Tab Content */}
            <div className={`tab-pane fade ${activeTab === 'Upcoming Events' ? 'show active' : ''}`}>
              {upcomingEvents.length > 0 ? (
                <EventCardList 
                  nextStep={nextStep} 
                  eventsAll={upcomingEventsObj} 
                  handleChange={handleChange} 
                  handleEventUpdate={handleEventUpdate} 
                  values={values} 
                  checkbox={true} 
                  count={true} 
                  markInactive={true} 
                  eventId={eventId}
                  parentActiveTab={activeTab}
                />
              ) : (
                <div className="container">
                  <div className="row">
                    <div className="col-12 mx-auto my-4 mw-container">
                      <p>No upcoming events at this time. Please check back later.</p>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Past Events Tab Content */}
            <div className={`tab-pane fade ${activeTab === 'Past Events' ? 'show active' : ''}`}>
              {pastEvents.length > 0 ? (
                <EventCardList 
                  nextStep={nextStep} 
                  eventsAll={pastEventsObj} 
                  handleChange={handleChange} 
                  handleEventUpdate={handleEventUpdate} 
                  values={values} 
                  checkbox={false} 
                  count={false} 
                  markInactive={true} 
                  eventId={eventId}
                  parentActiveTab={activeTab}
                />
              ) : (
                <div className="container">
                  <div className="row">
                    <div className="col-12 mx-auto my-4 mw-container">
                      <p>No past events to display.</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        events.map(function (event, i) {
          return <EventCardList 
            nextStep={nextStep} 
            event={event} 
            key={i} 
            handleChange={handleChange} 
            handleEventUpdate={handleEventUpdate} 
            values={values} 
            checkbox={true} 
            count={true} 
            markInactive={true} 
            eventId={eventId}
            parentActiveTab={activeTab}
          />
        })
      )}
    </>
  )
}

export default Events