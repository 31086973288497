import React from 'react'
import Header from './shared/Header';
import { useState, useEffect } from 'react';
import { USER_DETAILS } from './shared/Steps';
import DateRangeFormat from './DateRangeFormat';
import Countdown from "./Countdown";
import InterestListItem from './InterestListItem';
import { getLanguage } from './shared/Token';

const moment = require('moment');
require('moment-timezone');

const TicketCountSelect = ({ prevStep, nextStep, handleChange, values, handleEventUpdate }) => {
  var { scope, first_name, verificationError, organization, events, selected_event, organization_details, selected_occurrence, selected_ticket, org_id, meta } = values
  
  const language = getLanguage()
  
  const [pendingTickets, setPendingTickets] = useState(null)
  const [selectionValues, setSelectionValues] = useState(null);
  const [isPlusButtonDisabled, setIsPlusButtonDisabled] = useState(null)
  const [activeRow, setActiveRow] = useState(null);
  const [activeTab, setActiveTab] = useState('Tickets');
  const [hasTickets, setHasTickets] = useState(true);
  const [hasInterests, setHasInterests] = useState(false);
  const [interests, setInterests] = useState(null);

  const Continue = e => {
    e.preventDefault();
    nextStep(USER_DETAILS)
  }
 
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const Tab = ({ activeTab, label, onClick }) => (
    <li className="nav-item" role="presentation">
      <button className={activeTab === label ? 'active nav-link' : 'nav-link'} onClick={() => onClick(label)}
        data-bs-toggle="tab" type="button" role="tab" aria-selected={activeTab === label ? "true" : "false"}>{label}</button>
    </li>
  );

  useEffect(() => {
    var pendingTickets = null
    for (var event of events) {
      if (event.organization_event_occurrences && event.organization_event_occurrences.length > 0) {
        for (var occurrence of event.organization_event_occurrences) {
          if (occurrence.id === selected_occurrence) {
            let ticketInfo = {
              tickets: occurrence.tickets,
              organization_event_occurrence: occurrence,
              organization_event: event
            }

            const numberOfTickets = occurrence.tickets?.length || 0;
            setSelectionValues(Array(numberOfTickets).fill(0));
            setIsPlusButtonDisabled(Array(numberOfTickets).fill(false));
            
            // Check if there are any tickets
            const hasAnyTickets = numberOfTickets > 0;
            setHasTickets(hasAnyTickets);
            
            // If no tickets, set active tab to About by default
            if (!hasAnyTickets) {
              setActiveTab('About');

              const numberOfInterests = occurrence.interests?.length || 0;
              const hasInterests = numberOfInterests > 0
              setHasInterests(hasInterests)

              if (hasInterests) {
                setActiveTab('Interests');
                setInterests(occurrence.interests)
              }
            }

            pendingTickets = ticketInfo
            break;
          }
        }
      }
    }
    setPendingTickets(pendingTickets)
  }, []);

  const onChangeTicketTransactionMeta = (ticketsId, value) => {
    value = parseInt(value)
    var newMeta
    newMeta = { tickets: [{ id: ticketsId, count: value }] }
    handleChange('meta', newMeta)
  }

  const handleCountdownFinished = (eventId, occurrenceId, ticketId) => {
    // Create a new array of occurrences with the updated is_active property
    const updatedOccurrences = event.organization_event_occurrences.map((occurrence) => {
      if (occurrence.id === occurrenceId) {
        const updatedTickets = occurrence.tickets.map((ticket) => {
          if (ticket.id === ticketId) {
            return { ...ticket, is_active: true };
          }
          return ticket;
        });

        return { ...occurrence, tickets: updatedTickets };
      }

      return occurrence;
    });

    // Create a new event object with the updated occurrences
    const updatedEvent = { ...event, organization_event_occurrences: updatedOccurrences };

    // Call the handleEventUpdate callback function to pass the updated event back to the Main component
    handleEventUpdate(updatedEvent);
  };

  const toPriceString = (priceInSmallestUnit, currency) => {
    return parseFloat(priceInSmallestUnit / 100).toFixed(2) + ' ' + currency
  }

  const renderInterests = () => {
    if (!interests || interests.length < 1) {
      return null
    }

    return interests.map(function (interest, i) {
        return <InterestListItem key={i} showCountDown={true} interest={interest} organization_details={organization_details} language={language} /> 
    })
  }

  const handleIncrement = (index, maxLimit, ticketId) => {
    if (selectionValues[index] < maxLimit) {
      const updatedValues = [...selectionValues];
      updatedValues[index] = selectionValues[index] + 1;
      setSelectionValues(updatedValues);
      setActiveRow(index);

      handleChange('selected_ticket', parseInt(ticketId))
      onChangeTicketTransactionMeta(ticketId, selectionValues[index] + 1);

      if (selectionValues[index] + 1 === maxLimit) {
        // Set the disabled state of the plus button when it reaches the max
        const updatedDisabledState = [...isPlusButtonDisabled];
        updatedDisabledState[index] = true;
        setIsPlusButtonDisabled(updatedDisabledState);
      }
    }
  };

  const handleDecrement = (index, ticketId) => {
    if (selectionValues[index] > 0) {
      const updatedValues = [...selectionValues];
      updatedValues[index] = selectionValues[index] - 1;
      setSelectionValues(updatedValues);
      if (index === activeRow && updatedValues[index] === 0) {
        setActiveRow(null);
      }

      onChangeTicketTransactionMeta(ticketId, selectionValues[index] - 1);

      const updatedDisabledState = [...isPlusButtonDisabled];
      updatedDisabledState[index] = false;
      setIsPlusButtonDisabled(updatedDisabledState);
    }
  };

  const renderTicket = (ticket, eventId, occurrenceId, index) => {
    const stockholmTime = moment().tz('Europe/Stockholm');
    const stockholmTimeString = stockholmTime.format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';

    let upcomingTicket = ticket.start_date > stockholmTimeString

    // if ((!ticket.is_active && !upcomingTicket)) {
    //   return;
    // }

    return <div className="card-footer overflow-hidden event-ticket p-0 m-0 bg-light" key={ticket.id}>
      <div className="d-block px-0" htmlFor={ticket.id} key={ticket.id}>
        <div className={ticket.ticket_statistics.count === 0 || upcomingTicket || !ticket.is_active ? 'sold row align-items-center bg-white event-ticket-date' : 'row align-items-center bg-white event-ticket-date'} key={ticket.id}>
          <div className="col-6">
            <dl className="name">
              <span>{ticket.name}</span>
              <dt className="event-price">{toPriceString(ticket.price, ticket.currency)}</dt>

              {ticket.ticket_statistics && ticket.ticket_statistics.count !== 0 && ticket.is_active &&
                <span className="col-12 upcoming">
                  <Countdown countMessage={'Available for:'} eventId={eventId} occurrenceId={occurrenceId} ticketId={ticket.id} startDate={ticket.end_date} onCountdownFinished={handleCountdownFinished} />
                </span>
              }
            </dl>
          </div>
          <div className="col-6">
            <div data-index={index} key={ticket.id} className={`d-flex justify-content-end align-items-center ${activeRow === index ? 'active' : ''}`}>
              <button className="round-button" id="minus-button" disabled={selectionValues[index] === 0 || !ticket.is_active || ticket.ticket_statistics.count === 0} onClick={() => handleDecrement(index, ticket.id)}>-</button>
              <span id="selection-value"><span id="selection-value">{selectionValues[index]}</span></span>
              <button className="round-button" id="plus-button" onClick={() => handleIncrement(index, ticket.max_count_per_member, ticket.id)} disabled={(activeRow !== null && activeRow !== index) || !ticket.is_active || ticket.ticket_statistics.count === 0 || isPlusButtonDisabled[index]}>+</button>
            </div>
          </div>
          
          {true ?
            !ticket.is_active && upcomingTicket ?
              <span className="col-6 upcoming">
                <Countdown countMessage={'Releasing in:'} eventId={eventId} occurrenceId={occurrenceId} ticketId={ticket.id} startDate={ticket.start_date} onCountdownFinished={handleCountdownFinished} />
              </span>
              : !ticket.is_active ?
                <span className="col-6 upcoming">Ticket Sale has ended</span>
                : ticket.ticket_statistics.count === 0 ?
                  <span className="col-6 upcoming">Sold Out</span>
                  :
                  ''
            : ''}
        </div>
      </div>
    </div>
  }

  let occurrence = pendingTickets?.organization_event_occurrence
  let event = pendingTickets?.organization_event

  return (
    <>
      <Header organization_details={organization_details ? organization_details : ''} title="Select quantity" first_name={first_name} />
      <div className="container membership ticketSelect">
        <div className="row overflow-hidden">
          <div className="col-12 mx-auto">
            <div className="card mw-container">
              {event && event.image_url && <img src={event.image_url} className="w-100" alt="" />}
              <div className="card-body bg-white d-flex justify-content-between align-items-center">
                <div>
                  {event && <DateRangeFormat occurrence={occurrence} organization={event.organization} compactStyle={true} />}
                </div>
                {organization_details?.logo_image_url && 
                    <a href={`/${organization_details.organization_id}`}>
                      <img src={organization_details.logo_image_url} height={65} alt="logo" className="unionLogo" />
                    </a>
                  }
              </div>

              {/* Tabs Navigation */}
              <ul className="nav nav-pills" id="eventTab" role="tablist">
                {hasTickets && (
                  <Tab activeTab={activeTab} label="Tickets" onClick={handleTabClick} />
                )}

                {hasInterests && (
                  <Tab activeTab={activeTab} label="Interests" onClick={handleTabClick} />
                )}
                <Tab activeTab={activeTab} label="About" onClick={handleTabClick} />
              </ul>

              {/* Tab Content */}
              {activeTab === 'Tickets' && (
                <>
                  {pendingTickets?.tickets && pendingTickets.tickets.map((ticketItem, index) => {
                    return renderTicket(ticketItem, event.id, occurrence.id, index)
                  })}
                </>
              )}

              {activeTab === 'About' && (
                <div className="card-footer">
                  <div className="card-text">
                    {event && event.content ? (
                      <div dangerouslySetInnerHTML={{ __html: event.content }} />
                    ) : (
                      <p>No additional information available for this event.</p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {activeTab === 'Tickets' && (
          <div className="row">
            <div className="col-12">
              <div className="mw-container mx-auto mt-5 pb-5">
                <div className="d-flex justify-content-between ">
                  <button 
                    type="submit" 
                    onClick={Continue} 
                    disabled={(meta && meta.tickets[0].count === 0) || meta === null} 
                    className="btn btn-primary btn-lg btn-block w-100 mb-4 px-5"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {activeTab === 'Interests' && (
            renderInterests()
        )}
        

      </div>
    </>
  )
}

export default TicketCountSelect