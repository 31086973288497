import React, { useState } from 'react';
import { getInterestName, getInterestShortDescription } from './shared/Util';
import ArrowSvg from './shared/arrow.svg';
import Countdown from './Countdown';

const moment = require('moment');
require('moment-timezone');

const InterestListItem = ({ interest, showCountDown, organization_details, language }) => {

  const [_, forceUpdate] = useState(0);

  const isInterestActive = (interest) => {
    if (!interest) {
      return false
    }

    const stockholmTime = moment().tz('Europe/Stockholm');
    const now = stockholmTime.format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
    
    const interestStartDate = interest.start_date;
    const interestEndDate = interest.end_date;
    const isInterestActive = (!interestEndDate || interestEndDate >= now) && (!interestStartDate || now >= interestStartDate);

    return isInterestActive
  }

  const onClickInterest = (e, interest) => {
    if (!isInterestActive(interest)) {
      e.preventDefault()
    }
  }

  const handleCountdownFinished = (eventId, occurrenceId, ticketId) => {
    forceUpdate(prev => prev + 1);
  };

  const stockholmTime = moment().tz('Europe/Stockholm');
  const stockholmTimeString = stockholmTime.format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';   
  const isUpcoming = interest.start_date && interest.start_date > stockholmTimeString
  const isActive = isInterestActive(interest)

  return (

    <>
      {interest &&
        <div className="container membership packages">
          <div className="row overflow-hidden">

            <div className="col-12 mx-auto" id={'interest-' + interest.id}>

                <div className="row overflow-hidden"><div className="col-12 mx-auto">
                    <div className="card mw-container">

                        <a href={'/' + organization_details.organization_id + '/interests/' + interest.id} onClick={(e) => onClickInterest(e, interest)} style={{cursor: isActive ? 'pointer' : 'default'}}>
                        <div className="card-body bg-white d-flex justify-content-between align-items-center ">

                            <div className='col-8'>
                              <div>
                                <h6 className="card-title">{getInterestName(interest, language)}</h6>
                              </div>

                              {(showCountDown && isUpcoming) ? <span className="col-6 upcoming" style={{color: 'black'}}>
                                <Countdown countMessage={'Releasing in:'} eventId={null} occurrenceId={null} ticketId={null} startDate={interest.start_date} onCountdownFinished={handleCountdownFinished} />
                              </span> : null}


                              {(showCountDown && isActive) ? <span className="col-12 upcoming" style={{color: 'black'}}>
                                <Countdown countMessage={'Available for:'} eventId={null} occurrenceId={null} ticketId={null} startDate={interest.end_date} onCountdownFinished={handleCountdownFinished} />
                              </span> : null}

                            </div>

                            <div className="form-check" >
                              {<img src={ArrowSvg} alt="Arrow" style={{ opacity: isActive ? 1 : 0.5 }} />}
                            </div>
                        </div>

                        <div className="card mw-container packages-item">
                          <div className="card-footer">
                            <div className="card-text">
                              <div dangerouslySetInnerHTML={{ __html: getInterestShortDescription(interest, language) }} />
                            </div>
                          </div>
                        </div>

                        </a>

                    </div>
                  </div>
                </div>

            </div>

          </div>
        </div >
      }
    </>
  )

}

export default InterestListItem