export const getInterestName = (interest, language) => {
    const defaultName = interest?.name ?? ""
    if (language === 'en' && interest?.name_en) {
        return interest?.name_en
    }
    return defaultName
  }

export const getInterestDescription = (interest, language) => {
    const defaultDescription = interest?.description ?? ""
    if (language === 'en' && interest?.description_en) {
        return interest?.description_en
    }
    return defaultDescription
}

export const getInterestShortDescription = (interest, language) => {
    const defaultShortDescription = interest?.short_description ?? ""
    if (language === 'en' && interest?.short_description_en) {
        return interest?.short_description_en
    }
    return defaultShortDescription
}

export const getFormName = (form, language) => {
    const defaultName = form?.name ?? ""
    if (language === 'en' && form?.name_en) {
        return form?.name_en
    }
    return defaultName
}

export const getFormItemTitle = (formItem, language) => {
    const defaultTitle = formItem?.title ?? ""
    if (language === 'en' && formItem?.title_en) {
        return formItem?.title_en
    }
    return defaultTitle
}

export const getLocalizedFormOptionValue = (formOption, language) => {
    const defaultValue = formOption?.value ?? ""
    if (language === 'en' && formOption?.value_en) {
        return formOption?.value_en
    }
    return defaultValue
}

export const getLocalizedFormOptionPlaceholder = (formOption, language) => {
    const defaultPlaceholder = formOption?.placeholder ?? ""
    if (language === 'en' && formOption?.placeholder_en) {
        return formOption?.placeholder_en
    }
    return defaultPlaceholder
}

export const getLocalizedOrganizationName = (occurrence, language) => {
    const name = occurrence?.organization_name?.[language]
    if (name) {
        return name
    }

    const defaultName = occurrence?.organization_name?.default
    if (defaultName) {
        return defaultName
    }

    return ""
}