import React, { useState } from 'react'
import Header from './shared/Header'
import ArrowSvg from './shared/arrow.svg';
import calenderSvg from './shared/calender.svg'
import membershipSvg from './shared/membership.svg'
import interestSvg from './shared/interest.svg'

export default function OrganizationHome({ values }) {
  const { organization, organization_details, org_id } = values;
  const [activeTab, setActiveTab] = useState('Services');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const Tab = ({ activeTab, label, onClick }) => (
    <li className="nav-item" role="presentation">
      <button className={activeTab === label ? 'active nav-link' : 'nav-link'} onClick={() => onClick(label)}
        data-bs-toggle="tab" type="button" role="tab" aria-selected={activeTab === label ? "true" : "false"}>{label}</button>
    </li>
  );

  if (!organization) {
    return (
      <>
        <Header title="Organization" organization_details={organization_details} />
        <div className="container membership">
          <div className="row">
            <div className="col-12 text-center py-4">
              <p>Loading organization information...</p>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Header title={organization.name} organization_details={organization_details} />
      <div className="container membership">
        <div className="row overflow-hidden">
          <div className="col-12 mx-auto">
            <div className="card mw-container">
              {organization_details && organization_details.image_url && <img src={organization_details.image_url} className="w-100 topHeader"  alt={organization.display_name} />}

              <div className="topRadius card-body bg-white d-flex justify-content-between align-items-center">
                <h5 className="card-title">{organization.display_name}</h5>
                {organization_details?.logo_image_url && (
                  <img src={organization_details.logo_image_url} height={65} alt="logo" className="unionLogo" />
                )}
              </div>

              <ul className="nav nav-pills" id="orgTab" role="tablist">
                <Tab activeTab={activeTab} label="Services" onClick={handleTabClick} />
                <Tab activeTab={activeTab} label="About" onClick={handleTabClick} />
              </ul>

              {activeTab === 'About' && (
                <div className="card-footer">
                  <div className="card-text">
                    {organization_details?.about_us ? (
                      <div dangerouslySetInnerHTML={{ __html: organization_details.about_us }} />
                    ) : (
                      <p>No description available for this organization.</p>
                    )}
                    
                    {organization_details?.contact_info && (
                      <div className="mt-4">
                        <h6 className="mb-2"><strong>Contact Information</strong></h6>
                        <div dangerouslySetInnerHTML={{ __html: organization_details.contact_info }} />
                      </div>
                    )}
                  </div>
                </div>
              )}

              {activeTab === 'Services' && (
                <div className="card-footer overflow-hidden p-0 m-0 bg-light">
                  
                  <div className="col-12">
                    <a href={`/${org_id}/events`} className="text-decoration-none text-dark">
                      <div className="event-ticket-date bg-white p-3">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="icon-container" style={{ width: '40px' }}>
                            <img src={calenderSvg} alt="" width={25} />
                          </div>
                          <div className="flex-grow-1 text-center">
                            <strong className='orgHomeSec'>Events</strong>
                            <span className="orgHomeSecSub">Check out our upcoming events</span>
                          </div>
                          <div className="arrow-container" style={{ width: '40px', textAlign: 'right' }}>
                            <img src={ArrowSvg} alt="Arrow" />
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="col-12">
                    <a href={`/${org_id}/packages`} className="text-decoration-none text-dark">
                      <div className="event-ticket-date bg-white p-3">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="icon-container" style={{ width: '40px' }}>
                            <img src={membershipSvg} alt="" width={25} />
                          </div>
                          <div className="flex-grow-1 text-center">
                            <strong className='orgHomeSec'>Memberships</strong>
                            <span className="orgHomeSecSub">See our available memberships</span>
                          </div>
                          <div className="arrow-container" style={{ width: '40px', textAlign: 'right' }}>
                            <img src={ArrowSvg} alt="Arrow" />
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="col-12">
                      <a href={`/${org_id}/interests`} className="text-decoration-none text-dark">
                        <div className="event-ticket-date bg-white p-3">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="icon-container" style={{ width: '40px' }}>
                              <img src={interestSvg} alt="" width={25} />
                            </div>
                            <div className="flex-grow-1 text-center">
                              <strong className='orgHomeSec'>Expression of interest</strong>
                              <span className="orgHomeSecSub">Get involved in our organization</span>
                            </div>
                            <div className="arrow-container" style={{ width: '40px', textAlign: 'right' }}>
                              <img src={ArrowSvg} alt="Arrow" />
                            </div>
                          </div>
                        </div>
                      </a>
                  </div>

                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}