import React from 'react';
import ticketSvg from './shared/ticket.svg';
import moment from 'moment';
import 'moment-timezone';

const TicketIndicator = ({ tickets }) => {
  if (!tickets || tickets.length === 0) return null;

  const stockholmTime = moment().tz('Europe/Stockholm');
  const stockholmTimeString = stockholmTime.format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';

  // Check ticket status
  const hasAvailableTickets = tickets.some(ticket => 
    ticket.is_active && 
    !ticket.sold_out && 
    !ticket.end_date_passed &&
    stockholmTimeString >= ticket.start_date
  );

  // Check if all tickets are sold out but we're still within the ticket period
  const hasActiveTicketPeriod = tickets.some(ticket => 
    !ticket.end_date_passed
  );
  
  const allSoldOut = tickets.every(ticket => 
    ticket.sold_out
  );

  // Only show status if there are available tickets or if all tickets are sold out
  // during an active ticket period
  if (!hasAvailableTickets && !(allSoldOut && hasActiveTicketPeriod)) {
    return null;
  }

  // Determine the display type
  let statusType = '';
  let statusText = '';
  
  if (hasAvailableTickets) {
    statusType = 'available';
    statusText = 'Tickets Available';
  } else if (allSoldOut && hasActiveTicketPeriod) {
    statusType = 'sold-out';
    statusText = 'Sold Out';
  } else {
    return null; // Don't show any indicator for other cases
  }

  return (
    <div className={`ticket-indicator ${statusType}`}>
      {/* <img src={ticketSvg} alt="Ticket" width={15} /> */}
      <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 4H20C21.1 4 22 4.9 22 6V10C20.9 10 20 10.9 20 12C20 13.1 20.9 14 22 14V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V14C3.1 14 4 13.1 4 12C4 10.9 3.1 10 2 10V6C2 4.9 2.9 4 4 4Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
      <span className="ticket-status">{statusText}</span>
      <style jsx>{`
        .ticket-indicator {
          display: inline-flex;
          align-items: center;
          border-radius: 12px;
          padding: 2px 8px;
          margin-left: 6px;
          font-size: 11px;
          font-weight: 500;
        }
        
        .ticket-indicator img {
          margin-right: 4px;
        }
        
        .ticket-status {
          white-space: nowrap;
        }
        .ticket-indicator svg {
            margin-right: 4px;
        }
        .available {
          background-color: #e7f7ed;
          color: #0a7c30;
          border: 1px solid #c3e6d1;
        }
        
        .sold-out {
          background-color: #fff2e8;
          color: #d4380d;
          border: 1px solid #ffcfb5;
        }
      `}</style>
    </div>
  );
};

export default TicketIndicator;